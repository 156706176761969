// ###############################################
// Main wrapper
// ###############################################
#main-wrapper {
  width: 100%;
  overflow: hidden;
}

// ###############################################
// Page Wrapper
// ###############################################
.page-wrapper {
  position: relative;
  background: $body-bg;
  display: none;
  transition: 0.2s ease-in;
}

.page-wrapper > .container-fluid {
  padding: $page-wrapper-container-padding;
  min-height: $container-min-height;
}

.page-wrapper > .page-content {
  padding: 30px;
  min-height: calc(100vh - 116px);
}

// ###############################################
// Footer
// ###############################################
.footer {
  background: $white;
  border-top: 1px solid $border-color;
  padding: $footer-padding;
}

// ###############################################
// Sticky inner left part
// ###############################################
.left-part {
  width: 200px;
  position: absolute;
  border-right: 1px solid $border-color;

  .filter-listing {
    height: calc(100vh - 242px);
    overflow: auto;
  }
}

.right-part {
  width: calc(100% - 200px);
  height: calc(100vh - 176px);
  margin-left: 200px;

  &.app-drawer-content {
    width: calc(100% - 260px);
    margin-left: 260px;
  }
}

.right-left-part {
  width: 300px;
  position: absolute;
  border-right: 1px solid $border-color;

  .listing-over {
    height: calc(100vh - 258px);
    overflow: auto;
  }

  .listing-email-over {
    height: calc(100vh - 294px);
    overflow: auto;
  }
}

.right-right-part {
  width: calc(100% - 300px);
  height: calc(100vh - 175px);
  overflow: auto;
  margin-left: 300px;
}

.reverse-mode {
  .left-part {
    right: 0;
    border-left: 1px solid $border-color;
  }

  .show-left-part {
    right: auto;
    left: -41px;
  }

  .right-part {
    margin-left: 0px;
    margin-right: $left-part-width;
  }
}

// ################################################################################# //
//  Vertical Layout
// ################################################################################# //
#main-wrapper[data-layout='vertical'] {
  // Topbar
  // ###############################################
  .topbar .top-navbar .navbar-header {
    //width: $sidebar-width-full;
    transition: 0.2s ease-in;
  }

  // Sidebar type=full
  // ###############################################
  &[data-sidebartype='full'] {
    .page-wrapper {
      margin-left: $sidebar-width-full;
    }
  }

  // Sidebar type=iconbar
  // ###############################################
  &[data-sidebartype='iconbar'] {
    // Page-wrapper
    // #############################################
    .page-wrapper {
      margin-left: $sidebar-width-iconbar;
    }

    // Left-sidebar & Navbar brand width same as sidebar width
    // #############################################
    .left-sidebar,
    .left-sidebar .sidebar-footer,
    .topbar .top-navbar .navbar-header,
    .sidebar-nav ul .sidebar-item {
      width: $sidebar-width-iconbar;
    }

    // Setting sidebar nav links
    // #############################################
    .sidebar-nav {
      ul .sidebar-item {
        .sidebar-link {
          background-color: red;
          display: block;
          white-space: nowrap;
          text-align: center;
          @extend %text_wrapping;
          padding: 12px 15px 12px 0;
          i,
          .feather {
            margin: 0 auto;
            display: block;
            width: auto;
          }
        }
      }
    }
  }

  // Sidebar type=overlay
  // ###############################################
  &[data-sidebartype='overlay'] {
    // navbar-header
    // ###############################################
    .topbar .top-navbar .navbar-header {
      width: $sidebar-width-full;
    }

    // left sidebar
    // ###############################################
    .left-sidebar {
      left: -$sidebar-width-full;
    }

    // When sidebar is open
    // ###############################################
    &.show-sidebar {
      .left-sidebar {
        left: 0;
      }
    }
  }

  // Sidebar Postions
  // ###############################################
  &[data-sidebar-position='fixed'] {
    // Topbar
    // #############################################
    .topbar .top-navbar .navbar-header {
      position: fixed;
      z-index: 10;
    }

    // Left sidebar
    // #############################################
    .left-sidebar {
      position: fixed;
    }
  }

  // Topbar Postions
  // ###############################################
  &[data-header-position='fixed'] {
    // Topbar
    // #############################################
    .topbar {
      position: fixed;
      width: 100%;
    }

    // Page wrapper
    // #############################################
    .page-wrapper {
      padding-top: $topbar-height;
    }
  }

  // Boxed Layout
  // ###############################################
  &[data-boxed-layout='boxed'] {
    position: relative;
    max-width: $boxed-width;
    margin: 0 auto;
    box-shadow: 1px 0 80px rgba(0, 0, 0, 0.2);
  }

  // Boxed Layout with fixed topbar
  // ###############################################
  &[data-boxed-layout='boxed'][data-header-position='fixed'] {
    .topbar {
      max-width: $boxed-width;
    }
  }
}

// ################################################################################# //
//  Vertical Layout - Responsive  Layout
// ################################################################################# //
// Desktop Screen
@include media-breakpoint-up(md) {
  #main-wrapper[data-layout='vertical'] {
    // Sidebar type=full
    // ###############################################
    &[data-sidebartype='full'] {
      // Topbar
      // ###############################################
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-full;
      }
    }

    // Sidebar type=full & overlay with fixed sidebar
    // ###############################################
    &[data-sidebar-position='fixed'][data-sidebartype='full'],
    &[data-sidebar-position='fixed'][data-sidebartype='overlay'] {
      // Topbar
      // ###############################################
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-full;
      }
    }

    // Sidebar type=mini-sidebar with fixed sidebar
    // ###############################################
    &[data-sidebar-position='fixed'][data-sidebartype='mini-sidebar'] {
      // Topbar
      // ###############################################
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-mini;
      }
    }

    // Sidebar type=iconbar with fixed sidebar
    // ###############################################
    &[data-sidebar-position='fixed'][data-sidebartype='iconbar'] {
      // Topbar
      // ###############################################
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-iconbar;
      }
    }

    // Sidebar type=mini-sidebar
    // ###############################################
    &[data-sidebartype='mini-sidebar'] {
      // Topbar
      // ###############################################
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-mini;

        // Hide logo text
        //
        .logo-text {
          display: none;
        }

        // on full sidebar or on hover state
        //
        &.expand-logo {
          width: $sidebar-width-full;

          .logo-text {
            display: block;
          }
        }
      }
    }

    // Mini-sidebar with fixed sidebar
    // ###############################################
    &[data-sidebar-position='fixed'][data-sidebartype='mini-sidebar'] {
      // Topbar
      //
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-mini;
      }
    }

    // Sidebar type=mini-sidebar
    // ###############################################
    &[data-sidebartype='mini-sidebar'] {
      // Page wrapper
      //
      .page-wrapper {
        margin-left: $sidebar-width-mini;
      }

      // Sidebar UI Lists
      //
      .sidebar-nav {
        .hide-menu,
        .has-arrow:after {
          display: none;
        }

        // Small Cap
        //
        .nav-small-cap {
          justify-content: center;

          i {
            display: block;
          }
        }

        // User Profile
        //
        .user-profile {
          .profile-img {
            width: $profile-pic-width-mini;

            &::before {
              width: $profile-pic-width-mini;
              height: $profile-pic-width-mini;
            }
          }

          .u-dropdown {
            display: none;
          }
        }

        ul {
          .sidebar-item {
            // Main nav links
            //
            .sidebar-link {
              padding: $minisidebar-link-padding;
            }

            // First Level
            //
            .first-level {
              padding: $minisidebar-first-level-padding;

              .sidebar-item .sidebar-link {
                padding: $minisidebar-link-first-level-padding;
              }

              .sidebar-item .sidebar-link i {
                display: block;
              }
            }
          }
        }
      }

      // Left sidebar
      //
      .left-sidebar {
        width: $sidebar-width-mini;

        .sidebar-footer {
          display: none;
        }

        &:hover {
          width: $sidebar-width-full;

          .sidebar-nav {
            .hide-menu,
            .has-arrow:after {
              display: block;
            }

            // Small Cap
            //
            .nav-small-cap {
              justify-content: flex-start;

              i {
                display: none;
              }
            }

            // User Profile
            //
            .user-profile {
              .profile-img {
                width: $profile-pic-width-full;

                &::before {
                  width: $profile-pic-width-full;
                  height: $profile-pic-width-full;
                }
              }

              .u-dropdown {
                display: block;
              }
            }

            // Sidebar Links
            //
            ul {
              .sidebar-item {
                .sidebar-link {
                  padding: $sidebar-link-padding;
                }

                .first-level {
                  padding: $sidebar-first-level-padding;

                  .sidebar-item .sidebar-link {
                    padding: $sidebar-first-level-link-padding;
                  }

                  .sidebar-item .sidebar-link i {
                    display: none;
                  }
                }
              }
            }
          }

          .sidebar-footer {
            display: flex;
          }
        }
      }
    }
  }
}

// Mobile Screen
//
@media (max-width: 766px) {
  //
  // Main wrapper
  #main-wrapper {
    // Sidebar type=mini-sidebar
    // ###############################################
    &[data-sidebartype='mini-sidebar'] {
      // Left sidebar
      //
      .left-sidebar,
      .left-sidebar .sidebar-footer {
        left: -$sidebar-width-full;
      }
    }

    // Open sidebar
    // ###############################################
    &.show-sidebar {
      .left-sidebar,
      .left-sidebar .sidebar-footer {
        left: 0;
      }
    }

    // Sidebar position fixed with vertical layout && both with fixed
    // ###############################################
    &[data-layout='vertical'][data-sidebar-position='fixed'],
    &[data-layout='vertical'][data-header-position='fixed'][data-sidebar-position='fixed'] {
      // Topbar
      //
      .topbar .top-navbar {
        .navbar-collapse {
          position: relative;
          top: $topbar-height;
        }
      }
    }
  }

  // Sticky inner sidebar
  //
  .left-part {
    position: fixed;
    left: -$left-part-width;
    background: $body-bg;
    z-index: 1;
    transition: 0.1s ease-in;

    // On visible state
    //
    &.show-panel {
      left: 0;
    }
  }

  .reverse-mode .left-part {
    right: -$left-part-width;
    left: auto;

    // On visible state
    //
    &.show-panel {
      right: 0;
    }
  }

  .right-part {
    width: 100%;
    margin-left: 0;
  }
}
