main {
  min-height: 80vh;
}

body {
  font-family: Roboto;
  font-size: 16pt;
  background-color: #fffef4;
  color: #3f3c30;
}

h1 {
  text-transform: none;
  letter-spacing: inherit; /* Needed to override bootstrap's value and use our Typography element's value */
}

h2 {
  padding: 0.5rem 0;
  letter-spacing: inherit; /* Needed to override bootstrap's value and use our Typography element's value */
}

h3 {
  padding: 1rem 0;
}

.beauty-link-container {
  width: 250px;
  height: 250px;
  min-width: 250px;
  max-width: 250px;
  min-height: 250px;
  max-height: 250px;
  border: 1px solid #a9a9a9;
  border-radius: 5px;

  /* Center align children */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Animate shadow and scale */
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.beauty-link-container:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: 'cover';
}

.cursor-pointer {
  cursor: pointer;
}

.down-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M 0 16 L 4 12 L 9 17 L 14 12 L 18 16 L 9 24 Z'/><path d='M0 0h60v60H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 10%;
  background-position-y: 8px;
  margin-right: 1rem;
  padding-right: 1rem;
  height: 48px;
  min-height: 48px;
  cursor: pointer;
}

.error {
  color: #bb0606;
  margin-left: 9px;
}

.full-height {
  height: 100vh;
}

.full-width {
  width: 100%;
}

.hidden {
  display: none;
}

.showDesktop {
  display: block;
}
.showMobile {
  display: none;
}
@media only screen and (max-width: 912px) {
  .showDesktop {
    display: none;
  }
  .showMobile {
    display: block;
  }
}

/* Navigation Bar */
/* Text Links */
.navbar-light .navbar-nav .nav-link {
  color: #28261b;
  font-family: Amiri;
  font-size: 11pt;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #28261b;
  margin-left: 32px;
  margin-bottom: 16px;
}

@media (max-width: 991px) {
  .navbar-light .navbar-nav .nav-link {
    margin: 24px 0 0 0;
  }
}

#basic-navbar-nav {
  margin-top:16px;
}

/* Highlight bar for active link */
a.active.nav-link.active {
  border-bottom: 5px solid #e6af2e;
}

/* Dropdown in Navigation Bar */
/* User dropdown menu background */
div.dropdown-menu.show {
  background-color: #635f4d;
  border: 2px solid #635f4d;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  color: #fffef4;
}
/* Each user dropdown item */
a.dropdown-item {
  color: #fffef4;
  font-family: Roboto;
  font-size: 14px;
}

.modal-body {
  background-color: #fffef4;
  border-radius: 8px;
  padding: 0;
  overflow: hidden;
}
.modal-content {
  border-radius: 18px;
}
#login-register-tab-new-user {
  border-radius: 8px;
}

.form-field-container {
  display: flex;
  flex-direction: column;
  min-width: 120px;
  position: relative;
  width: 100%;
}

.form-field-container input {
  width: 100%;
  height: 52px;
  padding: 10px 16px 0 16px;
  outline: 0;

  border-radius: 7px;
  background: #fffef4;
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 21px;
  color: #635f4d;
}

.form-field-container label {
  font-size: 16px;
  font-family: Roboto, Helvetica, sans-serif;
  padding: 0 12px;
  color: #999;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 14px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
}

.form-field-container:focus-within label {
  transform: translate(2px, -10px) scale(0.95);
  color: #635f4d;
}

.form-field-input-light {
  border: 2px solid #F1F0E0;
  border-radius: 7px;
  width: 100%;
}

.form-field-input-regular {
  border: 2px solid #635f4d;
  border-radius: 7px;
  width: 100%;
}

.form-field-input-error {
  border: 2px solid #c3181a;
  border-radius: 7px;
  width: 100%;
}

.form-field-container .form-field-input-value-exists {
  transform: translate(2px, -10px) scale(0.95);
  color: #635f4d;
}

.form-field-label-error {
  color: #c3181a;
}

/* Change input fields for disabled state */
.form-field-input-disabled {
  color: #635f4d;
  border: 2px solid #F1F0E0;
}

/* Change field labels for disabled state */
.form-field-container .form-field-label-disabled {
  color: #AAA897;
}

.form-field-label-background {
  background-color: #fffef4;
  padding: 0 8px;
}

.country-region-select {
  background-color: #fffef4;
  border: 2px solid #635f4d;
  border-radius: 7px;
  min-height: 44px;
  min-width: 160px;
  max-height: 60px;
  padding: 12px;
  /* color: #635f4d; */
  color: #999;
  font-size: 16px;
  letter-spacing: 1px;
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: 300;
}

.country-region-select-value-exists {
  background-color: #fffef4;
  border: 2px solid #635f4d;
  border-radius: 7px;
  min-height: 44px;
  min-width: 160px;
  max-height: 60px;
  padding: 8px;
  color: #635f4d;
  font-size: 21px;
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: 400;
}

.country-region-select-disabled {
  background-color: #fffef4;
  border: 2px solid #F1F0E0;
  border-radius: 7px;
  min-height: 44px;
  min-width: 160px;
  max-height: 60px;
  padding: 8px;
  color: #635f4d;
  font-size: 21px;
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: 400;
  background-image:none;
}

.country-region-select-error {
  background-color: #fffef4;
  border: 2px solid #c3181a;
  border-radius: 7px;
  min-height: 44px;
  min-width: 160px;
  max-height: 60px;
  padding: 12px;
  color: #999;
  background-image: url("data:image/svg+xml;utf8,<svg fill='maroon' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M 0 16 L 4 12 L 9 17 L 14 12 L 18 16 L 9 24 Z'/><path d='M0 0h60v60H0z' fill='none'/></svg>");
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M 0 16 L 4 12 L 9 17 L 14 12 L 18 16 L 9 24 Z'/><path d='M0 0h60v60H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 6px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

.custom-control-label {
  font-size: 16px;
}

.radio-toolbar {
  margin: 0;
  height: 40px;
}

.radio-toolbar input[type='radio'] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  cursor: pointer;
  display: inline-block;

  background-color: #f1f0e0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  border: 2px solid #c9c8be;
  box-sizing: border-box;

  text-align: center;

  margin-left: -1px;
  padding: 6px 20px;
}

.radio-toolbar input[type='radio']:focus + label {
  border: 2px solid #444;
}

.radio-toolbar input[type='radio']:checked + label {
  background-color: #3f3c30;
  border-color: #3f3c30;
  color: #fffef4;
}

.radio-option-left {
  border: 2px solid #c9c8be;
  border-right: none;
  border-radius: 8px 0 0 8px;
}
.radio-option-center {
  border: 2px solid #c9c8be;
  border-right: none;
}
.radio-option-right {
  border: 2px solid #c9c8be;
  border-radius: 0 8px 8px 0;
}

body {
  font-family: sans-serif;
}

.container {
  position: relative;
}

.list-body {
  position: absolute;
  z-index: 1;
  /* box-sizing: border-box; */
  width: 100%;
  background-color: #9e8949;
}

/* This fires as soon as the element enters the dom */
.list-transition-enter,
.list-transition-appear {
  /*We give the list the initial dimension of the list button */
  left: 100%;
}
/* This is where we can add the transition */
.list-transition-enter-active,
.list-transition-appear-active {
  left: 0%;
  transition: all 400ms ease-in;
}

/* This fires as soon as the this.state.showList is false */
.list-transition-exit {
  left: 0%;
}
/* fires as element leaves the DOM */
.list-transition-exit-active {
  left: 100%;
  transition: all 400ms ease-out;
}

/* The other one */
.caller-transition-enter,
.caller-transition-appear {
  /*We give the list the initial dimension of the list button */
  left: -100%;
}
/* This is where we can add the transition */
.caller-transition-enter-active,
.caller-transition-appear-active {
  left: 0%;
  transition: all 400ms ease-in;
}

/* This fires as soon as the this.state.showList is false */
.caller-transition-exit {
  left: 0%;
}
/* fires as element leaves the DOM */
.caller-transition-exit-active {
  left: -100%;
  transition: all 400ms ease-out;
}

/* --------------- Tabs --------------- */
.nav-tabs {
  border-bottom: 1px solid #c9c8be;
}
.nav-tabs .nav-link {
  /* Alternative - Light inactive tab */
  /* background-color: #faf8ef;
  border-bottom: 1px solid #c9c8be;
  color: #9e9b89; */

  /* Completely transparent inactive tab */
  background-color: #F1F0E0;
  border-bottom: 1px solid #c9c8be;
  color: #635f4d;

  height: 40px;
  width:184px;

  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: center;
  text-transform: uppercase;
}
.nav-tabs .nav-link.active {
  background-color: #fffef4;
  border-color: #c9c8be;
  
  border-radius: 7px 7px 0px 0px;
  color: #635f4d;
}
/* Hovering over inactive tab */
.nav-link:hover {
  border-color:#c9c8be !important;
  color: #635f4d;
}
a.active.nav-link.active {
  border-bottom: 0;
}
.tab-content {
  border: 1px solid #c9c8be;
  border-radius: 0 0 7px 7px;
  border-top: 0;
}

/* Dropdown override */
.btn-primary {
  color: #fff;
  background-color: #635f4d;
  border-color: #635f4d;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15);
}
.btn-primary:hover {
  color: #fff;
  background-color: #635f4d;
  border-color: #635f4d;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15),
    0 0 0 0.2rem rgba(241, 209, 120, 0.2);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15),
    0 0 0 0.2rem rgba(241, 209, 120, 0.2);
}
.btn-primary.focus,
.btn-primary:focus {
  color: #b08217;
  background-color: transparent;
  border-color: #b08217;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15),
    0 0 0 0.2rem rgba(241, 209, 120, 0.2);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15),
    0 0 0 0.2rem rgba(241, 209, 120, 0.2);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #b08217;
  border-color: #b08217;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(241, 209, 120, 0.2);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(241, 209, 120, 0.2);
}

/* Override FullLayout CSS */
/* page-content is the overall container. Make it smaller for small screens */

/* TRIAL: Trying to make dashboard wider. This works if we use <StyledContainer fluid> in the dashboard pages */
/* @media (max-width: 768px){
  .page-content {
    padding: 16px !important; 
    width: 1120px !important;
    max-width: 1120px !important;
  }
} */
/* END OF TRIAL */
@media (max-width: 540px){
  .page-content {
    padding: 0px !important; 
  }
}


.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.pswp__bg {
  background-color: #faf7e2 !important;
}
.pswp_bg image-zoom-background {
  background-color: #faf7e2 !important;
}

/* Carousel indicators */
.hero-carousel > div > ol > li {
  background-color: #E6AF2E;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 8px;
}

/* --------------- React Image Gallery --------------- */
/* Main image */
.image-gallery-image {
  background-color: #faf7e2;
  border-radius: 8px;
  object-fit: contain;
  width: 100%;
  height: auto;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

/* --------------- Thumbnail --------------- */
/* Thumbnail image */
.image-gallery-thumbnail-image {
  background-color: #faf7e2;
  border-radius: 8px;
  object-fit: cover;
}
/* Hovering over thumbnail */
.image-gallery-thumbnail:hover {
  border: 2px solid #E6AF2E !important;
  border-radius: 10px;  
  margin-bottom: 4px;
}
/* Default values to return to when animating out of hover state */
.image-gallery-thumbnail {
  border-radius: 10px;  
  margin-bottom: 4px;
}

/* Selected thumbnail */
.image-gallery-thumbnail.active {
  border: 2px solid #E6AF2E !important;
  border-radius: 10px;
}

/* Thumbnail bar background */
.image-gallery-thumbnails {
  background-color: #fffef4; 
}

/* Thumbnail bar background in fullscreen */
.image-gallery-content.fullscreen {
  background: #fffef4 !important;
}


/**
OVERRIDE: react-accessible-accordion
*/
.accordion {
  border:0 !important;
}
.accordion__item + .accordion__item {
    border-top: 0 !important;
}
.accordion__button:before {
  display:none !important;
}
.accordion__button { 
  background-color: transparent !important;
  color: #1761C0 !important;
  padding-bottom: 0 !important;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(227, 227, 227);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(227, 227, 227);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(227, 227, 227);
}