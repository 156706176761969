// #############################
//  Theme Skin Color Exension for changing navbar colors
// #############################
%skin_gredient_1 {
	background: #009efb;
	/* Old browsers */
    background: -moz-linear-gradient(left, #0178bc 0%, #00bdda 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #0178bc 0%, #00bdda 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #0178bc 0%, #00bdda 100%);
}

%skin_gredient_2 {
    background: #363d4a; 
}

%skin_gredient_3 {
    background: #0178bc;
    /* Old browsers */
    background: -moz-linear-gradient(left, #318f94 0%, #5ec58c 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #318f94 0%, #5ec58c 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #318f94 0%, #5ec58c 100%);
}

%skin_gredient_4 {
    background: #01c0c8;
    /* Old browsers */
    background: -moz-linear-gradient(left, #01c0c8 0%, #1f72a2 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #01c0c8 0%, #1f72a2 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #01c0c8 0%, #1f72a2 100%);
}

%skin_gredient_5 {
    background: #7460ee;
    /* Old browsers */
    background: -moz-linear-gradient(left, #533fd0 0%, #840fb5 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #533fd0 0%, #840fb5 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #533fd0 0%, #840fb5 100%);
}

%skin_gredient_6 {
    background: #fff;
}